
export default class MyStorage {
  constructor() {
    this.key_version = "1"
  }

  del(key) {
    if(typeof window === "undefined") return null
    key = key + this.key_version
    return window.localStorage.removeItem(key);
  }
    
  set(key, value, ttl=0) {
    if(typeof window === "undefined") return null
    key = key + this.key_version
    var item = {
      value: value,
    }
    
    if(ttl !=0) {
      const now = new Date()
      item['expiry'] = new Date().getTime() + (ttl * 1000)
    }
    
    return window.localStorage.setItem(key, btoa(JSON.stringify(item)))
  }

  get(key) {
    if(typeof window === "undefined") return null
    key = key + this.key_version

    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
      return null
    }

    const item = JSON.parse(atob(itemStr))

    if(item.expiry) {
      const now = new Date()

      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
      }
    }
    return item.value
  }

  deleteExpired () {
    var eachitem;
    var eachkey;
    var dummyitem;
    
    for (var i = 0; i < localStorage.length; i++){
      eachitem = localStorage.getItem(localStorage.key(i))
      eachkey = localStorage.key(i)

      // if (eachitem.includes("expiry")) {
      dummyitem = self.get(eachkey)
      // }
    }
  }
}

module.exports = MyStorage
